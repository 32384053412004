var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"associated-interfaces-and-services"},[_c('div',{staticClass:"carousel-content"},[_c('h2',{staticClass:"content-title"},[_vm._v("关联接口及服务")]),_c('div',{staticClass:"content-flow"},[_c('div',{staticClass:"flow-img",style:({
          'background-image': `url(${_vm.computeChangeBackgroudImg})`,
        })}),_c('el-carousel',{attrs:{"indicator-position":"none","arrow":"always"},on:{"change":_vm.handleChangeCarousel}},_vm._l((_vm.carouselList),function(carouselItem,index){return _c('el-carousel-item',{key:index,staticClass:"carousel-item"},[_c('div',{staticClass:"flow-list"},[_c('h2',{staticClass:"list-title"},[_vm._v(_vm._s(carouselItem.carouselTitle))]),_c('div',{staticClass:"list-tabs"},[(carouselItem.activeName)?_c('el-tabs',{model:{value:(carouselItem.activeName),callback:function ($$v) {_vm.$set(carouselItem, "activeName", $$v)},expression:"carouselItem.activeName"}},_vm._l((carouselItem.tabList),function(tabItem,index){return _c('el-tab-pane',{key:index,attrs:{"label":tabItem.activeName,"name":tabItem.activeName}},[_c('ul',{staticClass:"tabs-content"},_vm._l((tabItem.carouselData),function(item,index){return _c('li',{key:index,staticClass:"content-item"},[_c('router-link',{attrs:{"to":{
                          name: item.routerName,
                          params: {
                            unitId: item.unitId,
                            docId: item.docId,
                          },
                        },"tag":"a","target":"_blank"}},[_vm._v(_vm._s(item.name))])],1)}),0)])}),1):_vm._l((carouselItem.tabList),function(tabItem,index){return _c('div',{key:index,attrs:{"label":tabItem.activeName,"name":tabItem.activeName}},[_c('el-divider'),_c('ul',{staticClass:"tabs-content"},_vm._l((tabItem.carouselData),function(item,index){return _c('li',{key:index,staticClass:"content-item"},[_c('router-link',{attrs:{"to":{
                        name: item.routerName,
                        params: {
                          unitId: item.unitId,
                          docId: item.docId,
                        },
                      },"tag":"a","target":"_blank"}},[_vm._v(_vm._s(item.name))])],1)}),0)],1)})],2)])])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }